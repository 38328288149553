import React, { Component } from 'react';
import GIF from './GIF';
import axios from 'axios';

class GIFpage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            GIFs: []
        }
    }

    componentDidMount = () => {
        axios
            .get('https://api.giphy.com/v1/gifs/search?api_key=n65aKpHf0D4MhNvnYb1RSN91VhwGsXJv&limit=50&q=oat')
            .then(response => {
                this.setState({GIFs: response.data.data})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <div className="GIFpage">
                {this.state.GIFs.map(gif => <GIF gif={gif} key={gif.id} />)}
            </div>
        )
    }
}

export default GIFpage;