import React from 'react';
import logo from './logo.svg';
import './App.css';
import GIFpage from './components/GIFpage';
import Balloons from './components/balloons.png';

function App() {
  return (
    <div className="App">
      <h1 className="title">Happy <br/>68th Birthday, <br/>OatHat!</h1>
        <GIFpage />
        <img src={Balloons} className="balloons" alt="Balloons" width="800"/>
    </div>
  );
}

export default App;
