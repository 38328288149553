import React, { Component } from 'react';

class GIF extends Component {
    render () {
        return (
            <div>
                <img src={`https://media.giphy.com/media/${this.props.gif.id}/giphy.gif`} alt={this.props.gif.slug} width="400" className="GIFpic" />
            </div>
        )
    }
}

export default GIF;